import React, { useEffect } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../styles/jobs.scss';
import { Helmet } from 'react-helmet';

function Jobs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Careers with HortPlus</title>
        <meta
          name='description'
          content='   We build digital platform solutions that help agricultural
          industries achieve sustainability and business goals through
          innovative technology'
        />
      </Helmet>
      <HeaderExpanded
        title='Careers'
        image={
          process.env.GATSBY_S3_BUCKET_ASSETS + 'images/wellington_sunset.jpg'
        }
      />
      <Container className='my-5'>
        <Row>
          <Col xs={12} md={6} className='pr-md-5 mb-3 mb-md-0'>
            <p className='hp-font-28'>
              We build digital platform solutions that help agricultural
              industries achieve sustainability and business goals through
              innovative technology.
            </p>
            <p>
              HortPlus is an established software and AgTech company, and
              pioneers in the development of digital tools used by some of New
              Zealand’s largest growers and agricultural industries. We work
              closely with a range of institutions and companies to develop
              powerful platform solutions that connect science and data to
              provide insight into weather, disease risk, and irrigation.
            </p>
            <p>
              We have offices in <b>Wellington</b> and <b>Hawkes Bay</b>, New
              Zealand.
            </p>
          </Col>
          <Col
            xs={12}
            md={6}
            className='d-flex justify-content-center align-items-center'
          >
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                'images/hortplus_office.jpg'
              }
              alt='HortPlus'
              fluid
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className='hp-page hp-page--blue-grey'>
        <Container>
          <h1 className='hp-title hp-title--subtitle text-center mb-3'>
            Open Roles
          </h1>
          <Row className='mb-3'>
            <Col md={{ span: 8, offset: 2 }}>
              <div>
                <h3 className='hp-title hp-font-28 py-3'>No Positions Currently Open</h3>
                <p>
                  We are always keen to hear from people who are passionate
                  about Tech / Agriculture / Sustainability.
                </p>
                <p>
                  Please send your CV through to{' '}
                  <a href='mailto:jobs@hortplus.com?subject=HortPlus Careers'>
                    jobs@hortplus.com
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Jobs;
